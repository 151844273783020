<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ppmpModal"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>Purchase Requests</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="clickCloseMainDialogPR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col lg="12" class="mt-10">
              <v-data-table
                :headers="headers"
                :items="prList"
                :loading="loading"
                class="elevation-0"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-text-field
                      style="max-width: 300px"
                      v-model="searchText"
                      append-icon="mdi-magnify"
                      placeholder="Search for PR purpose"
                      color="green darken-2"
                      class="mb-2 mr-2"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:body="{ items }">
                  <tbody>
                    <tr v-for="item in items" :key="item.PRID">
                      <td>{{ item.PRCODETemp }}</td>
                      <td>{{ item.PrPurpose }}</td>
                      <td>{{ item.FundCode }}</td>
                      <td>{{ item.DateCreated }}</td>
                      <td>
                        <v-icon small class="mr-2" @click="viewPR(item)">
                          visibility
                        </v-icon>
                        <v-icon
                          small
                          class="mr-2"
                          color="red accent-4"
                          @click="print(item)"
                        >
                          mdi-printer
                        </v-icon>
                      </td>
                    </tr>
                  </tbody>
                </template>
                <template v-slot:no-data> No Records Yet </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <prModlal :prData="prItem" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>

<script>
export default {
  props: {
    prData: {},
    dialogMenu: null,
  },
  components: {
    prModlal: () => import("./PRViewItem"),
  },
  data: () => ({
    dialog: false,
    searchText: "",
    searchTimeout: null,
    amountErr: null,
    editItemId: null,
    totalCount: 0,
    loading: true,
    options: {},
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Saved!",
      message: "",
      top: 10,
    },
    isLoading: false,
    menu: false,
    menu1: false,
    formData: {
      PPMPDescription: null,
      isDBM: null,
      BudgetID: null,
      Amount: null,
    },
    addPPMPDialog: false,
    prList: [],
    budgetList: [],
    prItem: [],

    headers: [
      {
        text: "PR No.",
        align: "start",
        sortable: false,
        value: "PRCODE",
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Purpose",
        value: "PrPurpose",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Fund",
        value: "FundCode",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "PR Date",
        value: "DateCreated",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "green darken-2 white--text text-caption font-weight-bold",
      },
    ],
  }),
  watch: {
    searchText: function () {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.initialize();
      }, 600);
    },
    prData: {
      handler(data) {
        if (data.PPMPID) {
          this.initialize();
        }
      },
      deep: true,
    },
    dialogMenu: {
      handler(data) {
        if (data) {
          this.dialog = true;
          this.initialize();
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.eventHub.$on("closePRItemodal", () => {
      this.prItem = {};
      this.initialize();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closePRItemodal");
  },
  methods: {
    print(data) {
      window.open(this.apiUrl + "/pr/report/" + data.PRID);
    },
    clickCloseMainDialogPR() {
      this.dialog = false;
      this.prList = [];
      this.eventHub.$emit("closeModalCons1", false);
    },
    viewPR(item) {
      this.prItem = item;
      console.log(this.prItem);
    },
    initialize() {
      this.loading = true;
      let data = new FormData();
      data.append("searchText", this.searchText);
      data.append("PRID", this.prData.PRID);
      this.axiosCall("/get/pr/consolidated", "POST", data).then((res) => {
        this.loading = false;
        this.prList = res.data.data.result;
      });
    },
  },
};
</script>